.transactionsWrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.transactionContainer {
    height: 500px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}


@media (max-width:1400px) {
    .transactionsWrapper {
        height: 80vh;
    }
}

.transactionstTitleContainer {
    text-align: center;
    padding: 16px;
    background-color: var(--font);
    border-radius: 100px;
}

.transactionsTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
    color: var(--primary);
}

.transactionsTable {
    width: 100%;
    margin: 0;
    position: relative;
    border-collapse: collapse;
}


.tableHeader {
    /* margin-top: 0;
    height: 50px; */
    padding: 16px;
    position: sticky;
    border-radius: 0px !important;
    top: -16px;
    background-color: var(--white);
    color: var(--primary);
}

.tableHeader:nth-child(1) {
    border-radius: 10px 0px 0px 10px;
}

.tableHeader:nth-child(6) {
    border-radius: 0px 10px 10px 0px;
}

.transactionsContainer {
    padding: 16px 0px 16px 16px;
    height: 500px;
    width: 700px;
    border-radius: 0px 0px 8px 8px;
    overflow-y: auto;
    overflow-x: auto;
}

.transactionsContainer::-webkit-scrollbar {
    width: 7px;
}

.transactionsContainer::-webkit-scrollbar-track {
    background: #fff;
    margin-top: 80px;
    margin-bottom: 30px;
}

.transactionsContainer::-webkit-scrollbar-thumb {
    background: rgb(105, 105, 105);
    border-radius: 10px !important;
}

.transactionsContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}