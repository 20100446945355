.cryptocurrencyWrapper {
    background-color: var(--primary);
}

.cryptoNftWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.cryptoNftWrapper::-webkit-scrollbar {
    display: none;
}

.cryptoNftContainer {
    width: 100%;
    height: 400px;
}

.cryptoNftTitleContainer {
    padding: 0px 16px;
    font-size: 30px;
    color: var(--font2);
    font-weight: bold;
    margin-bottom: 24px;
}

.cryptoContainer,
.nftContainer {
    padding: 0px 16px;
}

.cryptoTitleContainer,
.nftTitleContainer {
    color: var(--font2);
    font-size: 22px;
    padding: 24px 0px;
}

.cryptoParag,
.nftParag {
    font-size: 18px;
    color: var(--white);
    line-height: 30px;
}

.lottiFileCont {
    width: 100%;
    height: 100%;
}

.cryptocurrencyLottieContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100% !important;
}