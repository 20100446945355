.widthDrawWrapper {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.withDrawTitleContainer {
    color: var(--primary);
    background-color: var(--font);
    border-radius: 100px;
    padding: 16px;
    text-align: center;
}

.myWalletContainer,
.toWalletContainer,
.withdrawAmountContainer {
    margin-bottom: 16px;
}

.myWalletContainer p,
.toWalletContainer p,
.withdrawAmountContainer p {
    font-weight: 600;
    opacity: 0.8;
    margin-bottom: 8px !important;
}

.withdrawTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;
}

.maxAmount {
    width: 40% !important;
    border-radius: 10px !important;
    background-color: var(--font2) !important;
    color: var(--font) !important;
    margin-left: 8px !important;
}

.withdrawSubmitButton {
    margin-top: 12px !important;
    border-radius: 10px !important;
    background-color: var(--font2) !important;
    color: var(--font) !important;
    height: 48px;
}

.disabledWithdrawSubmitButton {
    width: 100% !important;
    margin-top: 12px !important;
    border-radius: 10px !important;
    height: 48px;
}

.successMessageContainer p {
    text-align: center;
    width: 100%;
    color: #228B22;
    font-size: 18px;
    font-weight: 600;
}

.errorMessageContainer {
    width: 100%;
    background: rgba(255, 122, 89, .5);
    height: 55px;
    border-radius: 8px;
}

.errorMessageContainer p {
    padding: 8px;
    color: red;
    font-size: 18px;
    font-weight: 600;
}

.formWrapper {
    padding: 16px 0px;
    margin-top: 16px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--font);
    border: 1px solid var(--primary);
}

@media screen and (max-width:600px) {
    .formWrapper {
        padding: 16px 16px !important;
    }
}