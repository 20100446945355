.footerWrapper {
    margin-bottom: 0;
    height: 20vh !important;
    background-color: var(--primary);
    padding: 4px;
    position: sticky;
}

.floatingContainer {
    width: 100%;
    height: 30vh;
    margin-top: 32px;
    border-radius: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.floatingContainer p {
    margin: 0;
    font-size: 2.5rem;
    color: var(--white);
    text-align: center;
}

.createAccount {
    font-size: 20px !important;
}

/*footer*/
.footerSectionsContainer {
    margin-top: 24px;
}

.footerSectionOneContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center !important;
}

.footerSectionTwoContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}

.footerSectionOneLinks,
.footerSectionTwoLinks {
    display: flex;
    color: var(--white);
    opacity: 0.5;
    cursor: pointer;
    text-align: center;
    margin-bottom: 1%;

    margin-top: 1%;
}

.footerLogo {
    width: 160px;
    height: 50px;
    background-image: url('../../assets/images/vodocryptologo.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.socialMediaContainer {
    display: flex;
}

.socialMediaContainer div {
    margin-right: 16px;
}

@media (max-width:700px) {
    .footerWrapper {
        height: auto;
    }

    .footerSectionOneContainer {
        flex-direction: column;
    }

    .floatingContainer p {
        font-size: 2rem;
    }
}

@media (max-width:500px) {
    .footerSectionTwoContainer {
        flex-direction: column;
    }

    .socialMediaContainer {
        margin-top: 16px;
    }

    .floatingContainer {
        height: 30vh;
    }

    .floatingContainer p {
        margin-bottom: 0;
        font-size: 1.7rem;
    }
}

@media (max-width:360px) {
    .floatingContainer p {
        padding-left: 4px;
        padding-right: 4px;
    }

    .createAccount {
        font-size: 16px !important;
    }
}

@media (max-width:335px) {
    .floatingContainer p {
        font-size: 1.5rem;
    }
}