
.cryptoWrapper {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    display: flex; 
    align-items: center;
    justify-content: center;
    height: auto;
}
.oneByOne {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    margin: 3%;
    background-color: var(--primary);background-image: linear-gradient(to bottom right, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    backdrop-filter: blur(10px);
    box-shadow: 10px 10px 10px rgba(30, 30, 30, 0.5);
    border-radius: 10px;
    padding: 12px;
}

.cryptoHeaderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    margin-top: 5%;
    margin-bottom: 5%;
    /* padding: 12px; */
}

.volume,
.low{
    text-align: right !important;
}

.cryptoFooterContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
    margin-top: 5%;
    margin-bottom: 5%;
    /* padding: 12px; */
}

.cryptoBodyContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100% !important;
}

.cryptoLogoName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cryptoLogo {
    width: 50px;
    aspect-ratio: 1/1;
    border-radius: 100px;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 4px;
}

.cryptoName,
.cryptoCurrency {
    margin: 0;
    color: var(--font2);
}

.cryptoCurrency {
    font-size: 18px;
    float: right;
}

.cryptoName {
    font-size: 17px;
    padding-left: 4px;
    font-weight: 400;
}

.cryptoName span {
    text-transform: uppercase;
}

.change,
.volume,
.high,
.low {
    margin-bottom: 4px;
    color: var(--font2);
    /* opacity: 0.7; */
    font-size: 12px;
    text-transform: uppercase;
}

.negChange {
    padding-top: 4px;
    padding-left: 4px;
    margin: 0;
    color: var(--font) !important;
    font-size: 14px;
}

.posChange {
    padding-top: 4px;
    padding-left: 4px;
    margin: 0;
    color: var(--font) !important;
    font-size: 14px;

}


.volumeValue,
.highValue,
.lowValue {
    padding-top: 4px;
    /* opacity: 0.8; */
    font-size: 12px;
    margin: 0;
    color: var(--font);
    float: right;
}

p{
    margin:0px !important;
    padding: 0px !important;
}

