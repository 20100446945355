.coinTableWrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.coinTableContainer {
    height: 55vh;
    overflow-y: auto;
    margin-top: 12px;
}

.coinTableContainer::-webkit-scrollbar {
    width: 7px;
}

.coinTableContainer::-webkit-scrollbar-track {
    background: var(--card);
    margin-top: 80px;
    margin-bottom: 30px;
}

.coinTableContainer::-webkit-scrollbar-thumb {
    background: rgb(105, 105, 105);
    /* border-radius: 100px !important; */
}

.coinTableContainer::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.coinTable {
    width: 100%;
    border-collapse: collapse !important;
    background-color: var(--primary) !important;

}

.css-34nofg-MuiTableRow-root:not(:last-of-type) {
    border-bottom: 2px solid var(--primary) !important;

}

.cryptoTableCell {
    margin-top: 0 !important;
    position: sticky !important;
    top: 0px !important;
    background-color: var(--white) !important;
    text-align: center !important;
    color: var(--primary) !important;
    font-weight: bold !important;
}

.marketTitle {
    /* width: 100% !important; */
    color: var(--primary) !important;
    margin-top: 6px;
    margin-bottom: 32px;
    text-align: center !important;
    font-size: 20px !important;
    background-color: var(--font);
    padding: 16px;
    border-radius: 100px;
}

.marketTitle p {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;

}


.cryptoMarketLogoName {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cryptoMarketLogo {
    width: 35px;
    height: 35px;
    aspect-ratio: 1/1;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-right: 4px;
}

.cryptoMarketName {
    font-size: 18px;
    margin: 0;
    margin-left: 8px;
    color: var(--font2) !important;
}

.cryptoMarketName span {
    text-transform: uppercase;
}

.Change {
    margin: 0;
    color: var(--font2) !important;
    font-weight: 600;
}


.search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search input {
    width: 50%;
    height: 50px;
    font-size: 18px;
    padding-left: 16px;
    border: 1.5px solid #c4c4c4;
}

.MuiTableCell-root {
    border-bottom: none !important;
}

.css-11xur9t-MuiPaper-root-MuiTableContainer-root {
    box-shadow: none !important;
}