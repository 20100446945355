.profileWrapper {
    width: 100%;
    height: 70vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.profileTitleContainer {
    text-align: center;
    margin-bottom: 32px;
    padding: 16px;
    border-radius: 100px;
    color: var(--primary);
    background-color: var(--font);
}

.profileTitle {
    font-size: 30px;
    font-weight: 700;
    margin-top: 0px;
    margin-bottom: 0px;
}

.inputContainer {
    border-radius: 24px !important;
    border: 1px solid var(--primary);
    padding: 16px;
}

.nameInputContainer div p,
.emailInputContainer div p,
.walletPassInputContainer div p {
    color: var(--primary);
    margin-bottom: 4px !important;
}

.nameInputContainer,
.emailInputContainer,
.walletPassInputContainer {
    margin: 16px;
}

.nameInputContainer p,
.emailInputContainer p,
.walletPassInputContainer p {
    font-size: 16px;
    font-weight: 700;
    opacity: 0.8;
}

.nameInputContainer div,
.emailInputContainer div,
.walletPassInputContainer div {
    width: 50%;
}

.nameInputContainer,
.walletPassInputContainer,
.emailInputContainer {
    display: flex;
}

.profileInput {
    border-radius: 8px !important;
    font-size: 16px;
    height: 40px;
    width: 95%;
    padding-left: 12px;
    border: 1px solid #c4c4c4;
}

.MuiContainer-root .MuiContainer-maxWidthLg .css-1oqqzyl-MuiContainer-root {
    width: 50%;
    border-color: red;
}

.passwordContainer {
    margin-left: 16px;
    display: flex;
    justify-content: space-between;
}

.editButtonContainer {
    width: 40% !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.editButton {
    color: var(--font) !important;
    background-color: var(--font2) !important;
    border-radius: 10px !important;
    height: 50px;
    width: 100%;
}

.rightInput {
    margin-left: 16px;
}

@media (max-width:1300px) {
    .profileWrapper {
        height: auto;
    }

    .profileContainer {
        margin-top: 32px;
        margin-bottom: 32px;
    }
}

@media (max-width:400px) {
    .profileTitleContainer {
        margin-bottom: 32px;
    }

    .nameInputContainer,
    .walletPassInputContainer,
    .emailInputContainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .nameInputContainer div,
    .emailInputContainer div,
    .walletPassInputContainer div {
        width: 90%;
    }

    .passwordContainer {
        flex-direction: column;
    }

    .profileInput {
        width: 100%;
    }

    .rightInput {
        margin-left: 0px;
    }

    .editButtonContainer {
        margin-top: 16px;
        width: 100% !important;
    }

}