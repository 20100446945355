.blogWrapper {
    padding: 50px 0px 10px 0px;
    height: auto;
    margin: 0;
    background-color: var(--background2);
    margin-bottom: 3%;
}

.blogTitle p {
    margin-top: 0;
    color: var(--primary);
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 48px !important;
}

.scrollBlogContainer {
    width: 100%;
    height: 455px;
    overflow-y: scroll;
    display: flex;
}

/* width */
.scrollBlogContainer::-webkit-scrollbar {
    width: 10px;
}

/* Handle */
.scrollBlogContainer::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.blogContainer {
    width: 350px;
    margin-left: 16px;
    background-color: var(--white);
    margin-bottom: 16px;
    border-radius: 10px;
}

.blogImageFloatingContainer {
    position: relative;
}

.fixedText {
    height: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.blogImage {
    width: 350px;
    height: 320px;
    border-radius: 10px 10px 0px 0px;
}

.blogFloatingContainer {
    background-color: var(--card);
    position: absolute;
    left: 0px;
    bottom: 4px;
    z-index: 1000;
    padding: 0px 10px;
}

.footerNewsContainer {
    margin: 16px;
    padding: 16px;
    text-align: center;
    background-color: var(--primary);
    border-radius: 100px;
}

.footerNewsContainer a {
    text-decoration: none !important;
    color: white;
}

/*blogs centered*/
.css-toiu1y-MuiGrid-root {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:450px) {
    .scrollBlogContainer::-webkit-scrollbar {
        display: none;
    }

    .blogContainer {
        width: 300px;
        margin-left: 0px;
    }

    .blogImage {
        width: 300px;
    }
}

@media (max-width:350px) {
    .blogContainer {
        width: 250px;
        margin-left: 0px;
    }

    .blogImage {
        width: 250px;
        height: 300px;
    }
}