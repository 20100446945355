:root {
    --primary: #381662;
    --secondary: #060024;
    --secondary2: #4B4A54;
    --button1: #FDA736;
    --white: #f1f1f1;
    --black: #000;
    --card: #d6d6d6;
    --cardSubTitle: #023244;
    --font:#f724c9;
    --font2:#f4dd59;
}

@font-face {
    font-family:Arial, Helvetica, sans-serif;
    src: url(../src/assets/fonts/Lato-Regular.ttf);
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 100px !important;
    /* height: 50px !important; */
}

body {
    margin: 0px;
    font-family:Arial, Helvetica, sans-serif ;
}

body::-webkit-scrollbar {
    display: none;
}