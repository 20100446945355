.depositeWrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary) !important;
}

.depositeContainer {
    width: 400px;
}

.myWalletContainer {
    /* margin-top: 32px; */
    width: 100%;
}

.depositeTitleContainer {
    margin: 0;
    padding: 16px;
    text-align: center;
    background-color: var(--font);
    border-radius: 100px;
    color: var(--primary);
}

.depositeTitle {
    font-size: 30px;
    font-weight: 700;
    margin: 0px;

}

.depositFormContainer {
    width: 100% !important;
    margin-top: 24px !important;
    border: 1px solid var(--primary);
    border-radius: 16px !important;
}

.myWallet {
    font-weight: 600;
    opacity: 0.8;
    margin-bottom: 10px !important;
}

.inputDisabled {
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--font2);
    width: 96.5%;
    font-size: 18px;
    padding-left: 16px;
    color: black;
}

.copyButtonContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyButton {
    margin-top: 32px !important;
    height: 48px;
    width: 100% !important;
    font-size: 16px !important;
    border-radius: 10px !important;
    background-color: var(--font2) !important;
    text-transform: capitalize !important;
    color: var(--font) !important;
    font-weight: bold !important;
}