.helpBuisnessWrapper {
    height: auto;
    padding:48px 0px ;
    width: 100%;
    background-color: var(--primary);
}

.helpBuisnessContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.helpBuisnessTitle {
    margin-bottom: 48px;
    font-size: 30px;
    text-align: center;
    color: var(--font2);
}
.helpBuisnessListContainer{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}
.helpBuisnessList{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    border: 1.5px solid var(--font);
    border-radius: 10px;
    box-shadow: rgba(247,36,201, 0.35) 0px 5px 15px;
    width: 250px;
    height: 350px;
}
.helpBuisnessList p{
    margin-top:16px !important;
    color: var(--font);
    font-weight: bold;
}
.helpBuisnessList span{
    color: var(--font2);
    text-align: center;
    padding: 8px;
}

@media screen and (max-width:757.5px) {
    .helpBuisnessList{
        margin-top: 16px;
    }
}